import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom"; // to redirect to login page if not logged in
import NavBar from '../components/NavBar';

export default function Report() {

    const [data, setData] = useState([])
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const reportDate = new Date();

    useEffect(() => {
        var token = localStorage.getItem("token");
        if (!token) {
            console.log("No token found. Redirecting to login page");
            navigate("/login", { replace: true });
        }

        const headers = {
      Authorization: `Bearer ${token}`,
    };
    const reportUrl = `${process.env.REACT_APP_API_URL}/clientmatchesreport`;
    console.log("leads url", reportUrl);
    axios
      .get(reportUrl, { headers })
      .then((res) => {
        console.log("this is the data", res.data.uniqueClients);
        setData(res.data.uniqueClients);
      })
      .catch((err) => setError(err));
    }, [])

    return (
        <div className='container'>
          <NavBar />
        <h3>Clients Matches Report {reportDate.toDateString()}</h3>
        <table className='table table-striped'>
          <thead>
            <tr>  
              <th>Client</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Membership / till</th>
              <th>Last Date</th>
              <th>Accepted</th>
              <th>Rejected</th>
              <th>Others</th>
              <th>Coaching</th>
            </tr>
          </thead>
          {data.map((client) => (

            <tr key={client.id}>
              <td>{client.name_string}</td>
              <td>
                {client.gender===9500?'M':client.gender===9501?'F':'-'}
              </td>
              <td>{client.age}</td>
              <td>{client.end_date}</td>
              <td>{client.lastDate}</td>
              <td>{client.accepted}</td>
              <td>{client.rejected}</td>
              <td>{}</td>
              <td>{client.coaching}</td>
            </tr>
          ))}
        </table>
        {error && <div className='error'>{error.message}</div>}
        </div>
    )


}
