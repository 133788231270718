import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AboutMe from "./AboutMe";
import Bio from "./Bio";
import Personality from "./Personality";
import Hobbies from "./Hobbies";
import MyIdealPartner from "./MyIdealPartner";
import MyAlbum from "./MyAlbum";
import NavBarUser from "../NavBarUser";

export default function MultiStepForm() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    marital_status: "",
    relationship_goal: "",
    ethnicity: "",
    ethnicityOthers: "",
    dob: {
      day: "",
      month: "",
      year: "",
    },
    education: "",
    educationOthers: "",
    diet: "",
    dietOthers: "",
    gender: "",
    mobile_number: "",
    alternate_email: "",
    username: "",
    about_me: "",
  });

  const FormTitles = [
    "About Me",
    "Bio",
    "Personality",
    "Hobbies",
    "My Ideal Partner",
    "My Album",
  ];

  const maxSteps = FormTitles.length - 1; // page 1 = 0 index so -1

  const PageDisplay = () => {
    if (step === 0) {
      return (
        <AboutMe
          nextStep={nextStep}
          prevStep={prevStep}
          maxSteps={maxSteps}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
    if (step === 1) {
      return (
        <Bio
          nextStep={nextStep}
          prevStep={prevStep}
          maxSteps={maxSteps}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
    if (step === 2) {
      return (
        <Personality
          nextStep={nextStep}
          prevStep={prevStep}
          maxSteps={maxSteps}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
    if (step === 3) {
      return (
        <Hobbies
          nextStep={nextStep}
          prevStep={prevStep}
          maxSteps={maxSteps}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
    if (step === 4) {
      return (
        <MyIdealPartner
          nextStep={nextStep}
          prevStep={prevStep}
          maxSteps={maxSteps}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
    if (step === 5) {
      return (
        <MyAlbum
          nextStep={nextStep}
          prevStep={prevStep}
          maxSteps={maxSteps}
          step={step}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const percentStyle = (step) => {
    return { width: `${((step + 1) * 100) / FormTitles.length}%` };
  };

  // check if jwt token present after register or login to fill multi step form
  const navigate = useNavigate();
  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found. Redirecting to register page");
      navigate("/register", { replace: true });
    }
  }, []);

  return (
    <>
      <NavBarUser />
      <div className="form">
        <div className="progressbar">
          <div style={percentStyle(step)}></div>
        </div>
        <div className="form-container">
          <div className="header">
            <h1>{FormTitles[step]}</h1>
          </div>
          <div className="body">{PageDisplay()}</div>
        </div>
      </div>
    </>
  );
}
