import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

function GenderDropdown({ genderCallback }) {
  const [display, setDisplay] = useState("Gender");
  const [color, setColor] = useState("secondary");

  return (
    <Dropdown>
      <Dropdown.Toggle variant={color} id="dropdown-basic">
        {display}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            genderCallback(null);
            setDisplay("Gender");
            setColor("secondary");
          }}
        >
          Gender
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            genderCallback("9501");
            setDisplay("Male");
            setColor("primary");
          }}
        >
          Male
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            genderCallback("9500");
            setDisplay("Female");
            setColor("danger");
          }}
        >
          Female
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default GenderDropdown;
