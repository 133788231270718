import React, { useEffect, useState } from "react";

import ReactFlagSelect from "react-flags-select";

const CountryDropdown = ({ callback, initial }) => {
  const [selected, setSelected] = useState("select a country");

  useEffect(() => {
    setSelected(initial);
  }, []);

  const countryCodes = {
    AU: "+61",
    BE: "+32",
    BN: "+673",
    CA: "+1",
    DK: "+45",
    FI: "+358",
    FR: "+33",
    DE: "+49",
    HK: "+852",
    IN: "+91",
    ID: "+62",
    IE: "+353",
    JP: "+81",
    KR: "+82", // KP is North Korea, KR is South Korea
    LI: "+423",
    LU: "+352",
    MO: "+853",
    MY: "+60",
    NL: "+31",
    NZ: "+64",
    NO: "+47",
    PH: "+63",
    QA: "+974",
    SG: "+65",
    ZA: "+27",
    LK: "+94",
    SE: "+46",
    CH: "+41",
    TH: "+66",
    AE: "+971",
    GB: "+44",
    US: "+1",
    VN: "+84",
    OTH: "",
  };

  return (
    <div>
      <ReactFlagSelect
        selected={selected}
        countries={[
          "AU",
          "BE",
          "BN",
          "CA",
          "DK",
          "FI",
          "FR",
          "DE",
          "HK",
          "IN",
          "ID",
          "IE",
          "JP",
          "KR",
          "LI",
          "LU",
          "MO",
          "MY",
          "NL",
          "NZ",
          "NO",
          "PH",
          "QA",
          "SG",
          "ZA",
          "LK",
          "SE",
          "CH",
          "TH",
          "AE",
          "GB",
          "US",
          "VN",
        ]}
        onSelect={(code) => {
          setSelected(code);
          callback(countryCodes[code], code);
        }}
        searchable
        placeholder={selected}
      />
    </div>
  );
};

export default CountryDropdown;
