import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap-v5";
import CountryForm from "../components/CountryForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import CountryDropdown from "../components/CountryDropdown";

export default function Contact() {
  const { id, name } = useParams(); // from url path /interview/:id/:name
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    country_code: "",
    country_code_others: "",
    mobile_number: "",
    email: "",
    alternate_email: "",
  });

  const handleChange = (e) => {
    console.log("handleChange", e.target.name, e.target.value);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    var url = `${process.env.REACT_APP_API_URL}/updatecontactdetails/${id}`;
    console.log("api url", url);
    setStatus("updating...");
    setError("");
    axios
      .post(url, formData, { headers })
      .then((res) => {
        console.log(res.data);
        setStatus(res.data.message);
        setError("");
      })
      .catch((err) => {
        console.log(err);
        setError("error updating contact details");
        setStatus("");
      });
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    var url = `${process.env.REACT_APP_API_URL}/getcontactdetails/${id}`;
    console.log("api url", url);
    axios
      .get(url, { headers })
      .then((res) => {
        console.log(res.data);
        setStatus("loaded");
        setError("");
        setFormData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setError("error loading data");
        setStatus("");
        setData({});
      });
  }, []);

  return (
    <Container className="mt-4">
      <h3>Contact Form</h3>
      <div className="status">{status}</div>
      <div className="error">{error}</div>
      <Form onSubmit={handleSubmit}>
        {/* Country */}
        <Form.Group className="mb-3" controlId="country">
          <Form.Label>Country</Form.Label>
          <CountryDropdown
            initial={formData.country_code_others}
            callback={(countryCode, country) => {
              console.log("CountryForm callback", countryCode, country);
              setFormData({ ...formData, country_code_others: country });
            }}
          />
        </Form.Group>

        {/* Mobile Number */}
        <Form.Group className="mb-3" controlId="mobile_number">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="tel"
            name="mobile_number"
            placeholder="Enter your mobile number"
            value={formData.mobile_number}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Email */}
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Alternate Email */}
        <Form.Group className="mb-3" controlId="alternate_email">
          <Form.Label>Alternate Email</Form.Label>
          <Form.Control
            type="email"
            name="alternate_email"
            placeholder="Enter your alternate email"
            value={formData.alternate_email}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}
