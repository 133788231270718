export function getTextForMaritalStatus(statusInt) {
  switch (statusInt) {
    case 9000:
      return "Never married";
    case 9001:
      return "Widowed";
    case 9002:
      return "Separated";
    case 9003:
      return "Divorced";
    default:
      return "Unknown";
  }
}

export function getMaritalStatusForDropDown() {
  return [
    { value: null, text: "Marital Status", color: "secondary" },
    { value: 9000, text: "Never married", color: "primary" },
    { value: 9001, text: "Widowed", color: "primary" },
    { value: 9002, text: "Separated", color: "primary" },
    { value: 9003, text: "Divorced", color: "primary" },
  ];
}

export function getTextForReligion(rel) {
  switch (rel) {
    case "11001":
      return "Buddhism";
    case "11002":
      return "Christianity";
    case "11003":
      return "Catholicism";
    case "11004":
      return "Free Thinker";
    case "11005":
      return "Hinduism";
    case "11006":
      return "Judaism";
    case "11007":
      return "Islam";
    case "11008":
      return "No Religion";
    case "11009":
      return "Zoroastrianism";
    case "11010":
      return "Others";
  }
}

export function getReligionDropDown() {
  return [
    { value: null, text: "Religion", color: "secondary" },
    { value: 11001, text: "Buddhism", color: "primary" },
    { value: 11002, text: "Christianity", color: "primary" },
    { value: 11003, text: "Catholicism", color: "primary" },
    { value: 11004, text: "Free Thinker", color: "primary" },
    { value: 11005, text: "Hinduism", color: "primary" },
    { value: 11006, text: "Judaism", color: "primary" },
    { value: 11007, text: "Islam", color: "primary" },
    { value: 11008, text: "No Religion", color: "primary" },
    { value: 11009, text: "Zoroastrianism", color: "primary" },
    { value: 11010, text: "Others", color: "primary" },
  ];
}

export function getTextForEthnicity(eth) {
  switch (eth) {
    case 9200:
      return "Caucasian";
    case 9201:
      return "Arab";
    case 9202:
      return "Chinese";
    case 9203:
      return "Eurasian";
    case 9204:
      return "Korean";
    case 9205:
      return "Indian";
    case 9206:
      return "Japanese";
    case 9207:
      return "Malay";
    case 9208:
      return "Others";
  }
}

export function getEthnicityDropDown() {
  return [
    { value: null, text: "Ethnicity", color: "secondary" },
    { value: 9200, text: "Caucasian", color: "primary" },
    { value: 9201, text: "Arab", color: "primary" },
    { value: 9202, text: "Chinese", color: "primary" },
    { value: 9203, text: "Eurasian", color: "primary" },
    { value: 9204, text: "korean", color: "primary" },
    { value: 9205, text: "Indian", color: "primary" },
    { value: 9206, text: "Japanese", color: "primary" },
    { value: 9207, text: "Malay", color: "primary" },
    { value: 9208, text: "Others", color: "primary" },
  ];
}

export function getStatusDropDown() {
  return [
    { value: null, text: "Status", color: "secondary" },
    { value: 23, text: "Approved", color: "primary" },
    { value: 99, text: "BlackLabel", color: "primary" },
    { value: 31, text: "Coaching", color: "primary" },
    { value: 10, text: "Dnc", color: "primary" },
    { value: 96, text: "Expired", color: "primary" },
    { value: 21, text: "Followup", color: "primary" },
    { value: 20, text: "Headhunted", color: "primary" },
    { value: 98, text: "Luxe", color: "primary" },
    { value: 95, text: "Participant", color: "primary" },
    { value: 22, text: "Pending", color: "primary" },
    { value: 2, text: "Registered", color: "primary" },
    { value: 97, text: "Super Luxe", color: "primary" },
    { value: 100, text: "Waitlist", color: "primary" },
  ];
}
export function getTextForStatus(sta) {
  switch (sta) {
    case 2:
      return "Registered";
    case 20:
      return "Headhunted";
    case 21:
      return "Followup";
    case 22:
      return "Pending";
    case 23:
      return "Approved";
    case 95:
      return "Participant";
    case 96:
      return "Expired";
    case 97:
      return "Premium";
    case 98:
      return "Luxe";
    case 99:
      return "BlackLabel";
    case 100:
      return "WaitList";
    case 10:
      return "dnc";
    default:
      return "?";
  }
}

export function getTextForProfession(prof) {
  switch (prof) {
    case 10300:
      return "Accountant";
    case 10301:
      return "Actuary";
    case 10302:
      return "Academician";
    case 10303:
      return "Advertising";
    case 10304:
      return "Anthropologist";
    case 10305:
      return "Auditor";
    case 10306:
      return "Aviation";
    case 10307:
      return "Archeologist";
    case 10308:
      return "Architect";
    case 10309:
      return "Artist";
    case 10400:
      return "Banker";
    case 10401:
      return "Biologist";
    case 10402:
      return "Botanist";
    case 10403:
      return "Broadcaster";
    case 10404:
      return "Business owner";
    case 10405:
      return "Chemist";
    case 10406:
      return "Charter Agent / Ship Broker ";
    case 10407:
      return "Chemical Researcher";
    case 10408:
      return "Chemical Engineer";
    case 10409:
      return "CEO/C-suite executive";
    case 10410:
      return "Choreographer";
    case 10411:
      return "Coach";
    case 10412:
      return "Computer Programmer ";
    case 10413:
      return "Composer";
    case 10414:
      return "Conservator";
    case 10415:
      return "Dancer";
    case 10416:
      return "Dentist";
    case 10417:
      return "Doctor";
    case 10418:
      return "Dietitian";
    case 10419:
      return "Developer";
    case 10420:
      return "Director ";
    case 10421:
      return "Ecologist";
    case 10422:
      return "Economist";
    case 10423:
      return "Editor";
    case 10424:
      return "Educator";
    case 10425:
      return "Engineer";
    case 10426:
      return "Entrepreneur";
    case 10427:
      return "Environmentalist";
    case 10428:
      return "Exhibitions Manager";
    case 10429:
      return "Fashion Designer";
    case 10430:
      return "Film Critic / Film Maker ";
    case 10431:
      return "Financier ";
    case 10432:
      return "Financial Analyst";
    case 10433:
      return "Food Industry Technologist";
    case 10434:
      return "General Anesthetist";
    case 10435:
      return "General Practitioner";
    case 10436:
      return "Geologist";
    case 10437:
      return "Geophysicist";
    case 10438:
      return "Goldsmith";
    case 10439:
      return "Government";
    case 10440:
      return "Hotelier";
    case 10441:
      return "Herbalist";
    case 10442:
      return "Industrial engineer";
    case 10443:
      return "IT";
    case 10444:
      return "Insurance";
    case 10445:
      return "Investment Officer";
    case 10446:
      return "Investor";
    case 10447:
      return "Image Consultant";
    case 10448:
      return "Jeweler";
    case 10449:
      return "Judge";
    case 10450:
      return "Journalist";
    case 10451:
      return "Lawyer";
    case 10452:
      return "Lecturer";
    case 10453:
      return "Management consultant";
    case 10454:
      return "Marine Engineer";
    case 10455:
      return "Mathematician";
    case 10456:
      return "Mechanic";
    case 10457:
      return "Money Broker";
    case 10458:
      return "Microbiologist";
    case 10459:
      return "Miner / Mining ";
    case 10460:
      return "Model";
    case 10461:
      return "Multimedia designer";
    case 10462:
      return "Musician";
    case 10463:
      return "News Editor";
    case 10464:
      return "Nutritionist";
    case 10465:
      return "Nurse";
    case 10466:
      return "Optician";
    case 10467:
      return "Obstetrician &amp; Gynecologist";
    case 10468:
      return "Orthopedic Surgeon";
    case 10469:
      return "Orthodontist";
    case 10470:
      return "Pediatrician";
    case 10471:
      return "Politician";
    case 10472:
      return "Pharmacist";
    case 10473:
      return "Philosopher";
    case 10474:
      return "Photographer";
    case 10475:
      return "Physicist";
    case 10476:
      return "Pharmacist";
    case 10477:
      return "Pilot";
    case 10478:
      return "Political Scientist";
    case 10479:
      return "Producer";
    case 10480:
      return "Psychologist";
    case 10481:
      return "Psychotherapist";
    case 10482:
      return "Publisher";
    case 10483:
      return "Public Relations";
    case 10484:
      return "Radiologist";
    case 10485:
      return "Railway Engineer";
    case 10486:
      return "Sales and Marketing";
    case 10487:
      return "Scientist";
    case 10488:
      return "Safety Engineer";
    case 10489:
      return "Sculptor";
    case 10490:
      return "School Inspector";
    case 10491:
      return "Script Writer";
    case 10492:
      return "Spa Owner";
    case 10493:
      return "Stockbroker";
    case 10494:
      return "Systems Engineer";
    case 10495:
      return "Teacher";
    case 10496:
      return "Tax Adviser / Tax Specialist ";
    case 10497:
      return "Valuer";
    case 10498:
      return "Veterinarian ";
    case 10499:
      return "Viniculturist ";
    case 10599:
      return "Others ";
    case 104100:
      return "Real Estate";
  }
}

export function getProfessionDropDown() {
  return [
    { value: null, text: "Profession", color: "secondary" },
    { value: 10300, text: "Accountant", color: "primary" },
    { value: 10301, text: "Actuary", color: "primary" },
    { value: 10302, text: "Academician", color: "primary" },
    { value: 10303, text: "Advertising", color: "primary" },
    { value: 10304, text: "Anthropologist", color: "primary" },
    { value: 10305, text: "Auditor", color: "primary" },
    { value: 10306, text: "Aviation", color: "primary" },
    { value: 10307, text: "Archeologist", color: "primary" },
    { value: 10308, text: "Architect", color: "primary" },
    { value: 10309, text: "Artist", color: "primary" },
    { value: 10400, text: "Banker", color: "primary" },
    { value: 10401, text: "Biologist", color: "primary" },
    { value: 10402, text: "Botanist", color: "primary" },
    { value: 10403, text: "Broadcaster", color: "primary" },
    { value: 10404, text: "Business owner", color: "primary" },
    { value: 10405, text: "Chemist", color: "primary" },
    { value: 10406, text: "Charter Agent / Ship Broker ", color: "primary" },
    { value: 10407, text: "Chemical Researcher", color: "primary" },
    { value: 10408, text: "Chemical Engineer", color: "primary" },
    { value: 10409, text: "CEO/C-suite executive", color: "primary" },
    { value: 10410, text: "Choreographer", color: "primary" },
    { value: 10411, text: "Coach", color: "primary" },
    { value: 10412, text: "Computer Programmer ", color: "primary" },
    { value: 10413, text: "Composer", color: "primary" },
    { value: 10414, text: "Conservator", color: "primary" },
    { value: 10415, text: "Dancer", color: "primary" },
    { value: 10416, text: "Dentist", color: "primary" },
    { value: 10417, text: "Doctor", color: "primary" },
    { value: 10418, text: "Dietitian", color: "primary" },
    { value: 10419, text: "Developer", color: "primary" },
    { value: 10420, text: "Director ", color: "primary" },
    { value: 10421, text: "Ecologist", color: "primary" },
    { value: 10422, text: "Economist", color: "primary" },
    { value: 10423, text: "Editor", color: "primary" },
    { value: 10424, text: "Educator", color: "primary" },
    { value: 10425, text: "Engineer", color: "primary" },
    { value: 10426, text: "Entrepreneur", color: "primary" },
    { value: 10427, text: "Environmentalist", color: "primary" },
    { value: 10428, text: "Exhibitions Manager", color: "primary" },
    { value: 10429, text: "Fashion Designer", color: "primary" },
    { value: 10430, text: "Film Critic / Film Maker ", color: "primary" },
    { value: 10431, text: "Financier ", color: "primary" },
    { value: 10432, text: "Financial Analyst", color: "primary" },
    { value: 10433, text: "Food Industry Technologist", color: "primary" },
    { value: 10434, text: "General Anesthetist", color: "primary" },
    { value: 10435, text: "General Practitioner", color: "primary" },
    { value: 10436, text: "Geologist", color: "primary" },
    { value: 10437, text: "Geophysicist", color: "primary" },
    { value: 10438, text: "Goldsmith", color: "primary" },
    { value: 10439, text: "Government", color: "primary" },
    { value: 10440, text: "Hotelier", color: "primary" },
    { value: 10441, text: "Herbalist", color: "primary" },
    { value: 10442, text: "Industrial engineer", color: "primary" },
    { value: 10443, text: "IT", color: "primary" },
    { value: 10444, text: "Insurance", color: "primary" },
    { value: 10445, text: "Investment Officer", color: "primary" },
    { value: 10446, text: "Investor", color: "primary" },
    { value: 10447, text: "Image Consultant", color: "primary" },
    { value: 10448, text: "Jeweler", color: "primary" },
    { value: 10449, text: "Judge", color: "primary" },
    { value: 10450, text: "Journalist", color: "primary" },
    { value: 10451, text: "Lawyer", color: "primary" },
    { value: 10452, text: "Lecturer", color: "primary" },
    { value: 10453, text: "Management consultant", color: "primary" },
    { value: 10454, text: "Marine Engineer", color: "primary" },
    { value: 10455, text: "Mathematician", color: "primary" },
    { value: 10456, text: "Mechanic", color: "primary" },
    { value: 10457, text: "Money Broker", color: "primary" },
    { value: 10458, text: "Microbiologist", color: "primary" },
    { value: 10459, text: "Miner / Mining ", color: "primary" },
    { value: 10460, text: "Model", color: "primary" },
    { value: 10461, text: "Multimedia designer", color: "primary" },
    { value: 10462, text: "Musician", color: "primary" },
    { value: 10463, text: "News Editor", color: "primary" },
    { value: 10464, text: "Nutritionist", color: "primary" },
    { value: 10465, text: "Nurse", color: "primary" },
    { value: 10466, text: "Optician", color: "primary" },
    { value: 10467, text: "Obstetrician &amp; Gynecologist", color: "primary" },
    { value: 10468, text: "Orthopedic Surgeon", color: "primary" },
    { value: 10469, text: "Orthodontist", color: "primary" },
    { value: 10470, text: "Pediatrician", color: "primary" },
    { value: 10471, text: "Politician", color: "primary" },
    { value: 10472, text: "Pharmacist", color: "primary" },
    { value: 10473, text: "Philosopher", color: "primary" },
    { value: 10474, text: "Photographer", color: "primary" },
    { value: 10475, text: "Physicist", color: "primary" },
    { value: 10476, text: "Pharmacist", color: "primary" },
    { value: 10477, text: "Pilot", color: "primary" },
    { value: 10478, text: "Political Scientist", color: "primary" },
    { value: 10479, text: "Producer", color: "primary" },
    { value: 10480, text: "Psychologist", color: "primary" },
    { value: 10481, text: "Psychotherapist", color: "primary" },
    { value: 10482, text: "Publisher", color: "primary" },
    { value: 10483, text: "Public Relations", color: "primary" },
    { value: 10484, text: "Radiologist", color: "primary" },
    { value: 10485, text: "Railway Engineer", color: "primary" },
    { value: 10486, text: "Sales and Marketing", color: "primary" },
    { value: 10487, text: "Scientist", color: "primary" },
    { value: 10488, text: "Safety Engineer", color: "primary" },
    { value: 10489, text: "Sculptor", color: "primary" },
    { value: 10490, text: "School Inspector", color: "primary" },
    { value: 10491, text: "Script Writer", color: "primary" },
    { value: 10492, text: "Spa Owner", color: "primary" },
    { value: 10493, text: "Stockbroker", color: "primary" },
    { value: 10494, text: "Systems Engineer", color: "primary" },
    { value: 10495, text: "Teacher", color: "primary" },
    { value: 10496, text: "Tax Adviser / Tax Specialist ", color: "primary" },
    { value: 10497, text: "Valuer", color: "primary" },
    { value: 10498, text: "Veterinarian ", color: "primary" },
    { value: 10499, text: "Viniculturist ", color: "primary" },
    { value: 10599, text: "Others ", color: "primary" },
    { value: 104100, text: "Real Estate", color: "primary" },
  ];
}
