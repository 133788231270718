import React, { useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import Interview from "./Interview";
import Contact from "./Contact";

const TabsComponent = () => {
  // Define the active tab state
  const [activeTab, setActiveTab] = useState("interview");
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  // Define a function to switch tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const { id, name } = useParams(); // from url path /interview/:id/:name

  return (
    <div>
      <NavBar />

      {/* Tab Navigation */}
      <button onClick={handleBack} style={{ float: "right", margin: 10 }}>
        back
      </button>
      <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
        <h2 className="heading" style={{ marginRight: 10, marginLeft: 10 }}>
          {name}
        </h2>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "interview" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("interview")}
        >
          Interview
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "contact" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("contact")}
        >
          Contact
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "prequalify" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("prequalify")}
        >
          Pre Qualify
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "matches" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("matches")}
        >
          Matches
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "consultantsnotes" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("consultantsnotes")}
        >
          Consultant's notes
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "holdperiod" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("holdperiod")}
        >
          Hold Period
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "coaching" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("coaching")}
        >
          Coaching
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "special" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("special")}
        >
          Special
        </button>
        <button
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            backgroundColor:
              activeTab === "subscriptions" ? "#d2b48c" : "transparent",
          }}
          onClick={() => handleTabClick("subscriptions")}
        >
          Subscriptions
        </button>

        {/* Add more buttons for other tabs as needed */}
      </div>

      {/* Tab Content */}
      <div style={{ padding: "20px" }}>
        {activeTab === "interview" && <Interview />}
        {activeTab === "contact" && <Contact />}
        {activeTab === "prequalify" && (
          <div>Pre Qualify Content coming soon</div>
        )}
        {activeTab === "matches" && <div>Matches Content coming soon</div>}
        {activeTab === "consultantsnotes" && (
          <div>Consultant's notes Content coming soon</div>
        )}
        {activeTab === "holdperiod" && (
          <div>Hold Period Content coming soon</div>
        )}
        {activeTab === "coaching" && <div>Coaching Content coming soon</div>}
        {activeTab === "special" && <div>Special Content coming soon</div>}
        {activeTab === "subscriptions" && (
          <div>Subscriptions Content coming soon</div>
        )}
        {/* Add more content for other tabs */}
      </div>
    </div>
  );
};

export default TabsComponent;
